<template>
  <!-- api-key="AIzaSyA9iMpjstgjVffJc2sEPealV1IC4f4nCEQ" -->
  <GoogleMap api-key="AIzaSyAboxdDSraLV0k-tlVX0dSYIM3Fpp23EV0" style="height: 700px;" :center="mapCenter" :zoom="7">
    <div v-for="station in stations" :key="station.id">
      <Marker :options="{ position: station }" />
    </div>
  </GoogleMap>

</template>

<script>
import { defineComponent } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';

export default defineComponent({
  props: {
    serviceID: String
  },

  components: { GoogleMap, Marker },

  setup(props) {
    const palic = {
        lat       : 46.079240,
        lng       : 19.783400,
        name      : 'palic',
        id        : '151',
        services  : [610, 584, 572, 563, 524, 438, 327, 325, 324, 321, 43, 42, 40, 39, 38, 35]
    };

    const subotica1 = {
        lat       : 46.083230,
        lng       : 19.658870,
        name      : 'subotica1',
        id        : '011',
        services  : [610, 438, 325, 321, 32, 42, 38, 36, 35]
    };

    const subotica2 = {
        lat       : 46.100220,
        lng       : 19.688870,
        name      : 'subotica2',
        id        : '018',
        services  : [610, 572, 524, 438, 327, 325, 324, 321, 32, 33, 42, 40, 39, 38, 36, 35]
    };

    const subotica3 = {
        lat       : 46.092870,
        lng       : 19.660440,
        name      : 'subotica3',
        id        : '182',
        services  : [610, 524, 438, 327, 325, 324, 321, 32, 42, 40, 39, 38, 36, 35]
    };

    const beograd1 = {
        lat       : 44.798857,
        lng       : 20.372584,
        name      : 'beograd1',
        id        : '013',
        services  : [610, 524, 438, 327, 325, 321, 42, 40, 38, 35]
    };

    const beograd2 = {
        lat       : 44.821730,
        lng       : 20.536770,
        name      : 'beograd2',
        id        : '167',
        services  : [610, 438, 325, 321, 42, 40, 38, 35]
    };

    const beograd3 = {
        lat       : 44.823010,
        lng       : 20.480530,
        name      : 'beograd3',
        id        : '160',
        services  : [610, 438, 325, 321, 42, 38, 35]
    };

    const beograd4 = {
        lat       : 44.805410,
        lng       : 20.349780,
        name      : 'beograd4',
        id        : '168',
        services  : [610, 524, 438, 325, 321, 42, 40, 38, 35]
    };

    const zemun1 = {
        lat       : 44.852945,
        lng       : 20.369991,
        name      : 'zemun1',
        id        : '016',
        services  : [610, 524, 438, 327, 325, 321, 32, 33, 42, 40, 38, 36, 35]
    };

    const zemun2 = {
        lat       : 44.862155,
        lng       : 20.368119,
        name      : 'zemun2',
        id        : '156',
        services  : [610, 438, 325, 321, 42, 40, 38, 35
    ]};

    const zemun3 = {
        lat       : 44.836450,
        lng       : 20.369110,
        name      : 'zemun3',
        id        :  '169',
        services  : [610, 438, 325, 321, 42, 40, 38, 35
    ]};

    const noviSad1 = {
        lat       : 45.269286,
        lng       : 19.826193,
        name            : 'noviSad1',
        id        : '188',
        services  : [610, 572, 524, 438, 325, 321, 42, 40, 38, 35]
    };

    const noviSad2 = {
        lat       : 45.286830,
        lng       : 19.833100,
        name            : 'noviSad2',
        id        : '159',
        services  : [610, 572, 524, 438, 325, 321, 42, 40, 38, 35]
    };

    const backaTopola = {
        lat       : 45.810300,
        lng       : 19.630680,
        name            : 'backaTopola',
        id        : '158',
        services  : [610, 524, 438, 325, 321, 42, 40, 38, 35]
    };

    const tornjos = {
        lat       : 45.893590,
        lng       : 19.863480,
        name            : 'tornjos',
        id        : '161',
        services  : [610, 438, 325, 321, 42, 40, 38, 35]
    };

    const senta = {
        lat       : 45.914320,
        lng       : 20.102780,
        name            : 'senta',
        id        : '014',
        services  : [610, 572, 524, 438, 325, 321, 42, 38, 36, 35]
    };

    const kanjiza = {
        lat       : 46.051570,
        lng       : 20.038010,
        name            : 'kanjiza',
        id        : '154',
        services  : [610, 572, 524, 438, 325, 321, 32, 42, 40, 38, 36, 35]
    };

    const backiVinogradi = {
        lat       : 46.105090,
        lng       : 19.879770,
        name            : 'backiVinogradi',
        id        : '015',
        services  : [610, 438, 325, 321, 42, 38, 35]
    };

    const malePijace = {
        lat       : 46.072120,
        lng       : 19.924130,
        name            : 'malePijace',
        id        : '012',
        services  : [610, 438, 325, 321, 42, 38, 35]
    };

    const vrbas = {
        lat       : 45.570530,
        lng       : 19.656030,
        name            : 'vrbas',
        id        : '157',
        services  : [610, 572, 524, 438, 325, 321, 32, 42, 40, 38, 36, 35]
    };

    const zednik = {
        lat       : 45.923315,
        lng       : 19.634977,
        name      : 'zednik',
        id        : '153',
        services  : [610, 438, 325, 321, 42, 38, 35]
    };

    const baljevac = {
        lat       : 43.391320,
        lng       : 20.635910,
        name      : 'baljevac',
        id        : '184',
        services  : [610, 438, 325, 321, 42, 40, 38, 35]
    };

    const preljina = {
        lat       : 43.910937,
        lng       : 20.408215,
        name      : 'preljina',
        id        : '186',
        services  : [610, 524, 438, 325, 321, 42, 40, 38]
    };

    const pozarevac = {
        lat       : 44.610162,
        lng       : 21.181746,
        name      : 'pozarevac',
        id        : '185',
        services  : [610, 438, 325, 321, 42, 40, 38]
    };

    const nis = {
        lat       : 43.356803,
        lng       : 21.852020,
        name      : 'nis',
        id        : '173',
        services  : [524, 610, 42, 324, 325, 438, 321, 38, 40, 35]
    };

    const sombor = {
        lat       : 45.755772,
        lng       : 19.125715,
        name      : 'sombor',
        id        : '192',
        services  : [610, 572, 524, 325, 324, 321, 438, 33, 42, 40, 39, 38, 36, 35, 327]
    };

    var stations = {
        backaTopola,
        palic,
        subotica1,
        subotica2,
        subotica3,
        beograd1,
        beograd2,
        beograd3,
        beograd4,
        zemun1,
        zemun2,
        zemun3,
        noviSad1,
        noviSad2,
        tornjos,
        senta,
        kanjiza,
        backiVinogradi,
        malePijace,
        vrbas,
        zednik,
        baljevac,
        preljina,
        pozarevac,
        nis,
        sombor
    };

    // Return all the stations if there is not service ID sent in the URL
    if (!props.serviceID)
        return {stations: stations, mapCenter: stations['beograd1']};

    // Filter stations by the service (service ID from the url)
    for (let i in stations) {
        let station = stations[i];
        let services = station.services;

        if (!services.includes(parseInt(props.serviceID))) {
            delete stations[i];
        }
    }

     return {stations: stations, mapCenter: stations[Object.keys(stations)[0]]};
  },
})
</script>